<template>
  <div class="sales-by-title">
    <EbpCard>
      <b-row class="my-4" align-v="end">
        <!-- Date range -->
        <b-col :md="$isAdmin ? 2 : 3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("date-range") }}</p>
          <DatePicker
            type="month"
            v-model="date_range"
            :clearable="false"
            range
            :placeholder="$t('date-range')"
            valueType="format"
            format="MMM-YYYY"
          />
        </b-col>
        <b-col md="3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("books") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            v-model="titles"
            multiple
            :async="$isAdmin"
            :options="titleList"
            internal-search
            internal-search-key="label"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("sales-channels") }}</p>
          <TreeSelect
            :disabled="loading"
            v-model="salesChannels"
            :options="salesChannelList"
            multiple
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("authors") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            :options="authorList"
            v-model="authors"
            internal-search
            internal-search-key="first_name"
            multiple
            track-by="id"
            label="first_name"
            :async="$isAdmin"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0" v-if="$isAdmin">
          <p class="mb-1">{{ $t("client") }}</p>
          <AsyncTreeSelect
            v-model="clients"
            :async="$isAdmin"
            action="users/search"
            track-by="id"
            label="name"
            :disabled="loading"
          />
        </b-col>
        <b-col md="1" class="text-right">
          <ebp-button
            v-text="$t('export-csv')"
            @click="exportCSV"
            size="sm"
            :block="isSmallScreen"
            :loading="downloading"
          />
        </b-col>
      </b-row>
    </EbpCard>

    <!-- Map -->
    <EbpCard :loading="loading" class="position-relative">
      <GChart
        type="GeoChart"
        :data="dataSource"
        :settings="{ packages: ['geochart'] }"
      />

      <!-- Special regions -->
      <EbpCard class="special-sales">
        <p v-for="(item, i) in specialRegionSales" :key="i">
          {{
            $t("unspecified-apple", {
              territory: item.territory,
              sales: item.sales
            })
          }}
        </p>
      </EbpCard>
    </EbpCard>

    <!-- Table -->
    <AdvancedDataTable
      :headers="headers"
      :items="records"
      :paginate="false"
      :showLimit="false"
      :showTotal="false"
      hideActions
      sticky-header="60vh"
      :search="false"
      :loadingGlobal="loading"
      sortable
    >
      <template
        v-for="header in headers"
        :slot="`cell(${header.key || header})`"
        slot-scope="{ item }"
      >
        <b v-if="item.is_total" :key="header.key || header">{{
          item[header.key || header]
            ? formatNumber(item[header.key || header])
            : "-"
        }}</b>
        <span v-else :key="header.key || header">{{
          item[header.key || header]
            ? formatNumber(item[header.key || header])
            : "-"
        }}</span>
      </template>

      <template #cell(territory)="{ item }">
        <span v-tooltip="item.territory_name"
          ><b v-if="item.is_total">{{ item.territory }}</b>
          <template v-else>{{ item.territory }}</template></span
        >
      </template>
    </AdvancedDataTable>
  </div>
</template>

<script>
import salesChannelsCategories from "../../fixtures/sales-channel-categories";
import EbpCard from "../../components/core/ebp-card.vue";
import DatePicker from "../../components/core/DatePicker.vue";
import AdvancedDataTable from "../../components/advanced-data-table";
import { format, subMonths, differenceInMonths, parse } from "date-fns";
import { forEach, map, sumBy } from "lodash-es";
import TreeSelect from "../../components/core/tree-select";
import addMonths from "date-fns/addMonths";
import FileDownload from "js-file-download";
import AsyncTreeSelect from "@/components/AsyncTreeSelect";
const formatString = "MMM-yyyy";

import { GChart } from "vue-google-charts";

export default {
  name: "SalesByTitle",
  components: {
    EbpCard,
    DatePicker,
    TreeSelect,
    AdvancedDataTable,
    AsyncTreeSelect,
    GChart
  },
  data() {
    return {
      date_range: [
        format(subMonths(Date.now(), 12), formatString),
        format(Date.now(), formatString)
      ],
      titles: [],
      salesChannels: [],
      authors: [],
      clients: [],
      type: "all",
      showPaid: true,
      showFree: true,
      loading: false,
      groupedData: [],
      rawData: [],
      downloading: false,
      specialRegions: ["EU", "LL"]
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    formatData() {
      let records = {},
        totals = {};

      forEach(this.groupedData, data => {
        forEach(data, ({ data, period }) => {
          forEach(data, (authors, book_id) => {
            if (this.titles.length && !this.titles.includes(book_id)) return;

            forEach(authors, (sc_categories, author_name) => {
              // Filter authors
              if (
                (this.authors.length && !this.authors.includes(author_name)) ||
                author_name === "title"
              ) {
                return;
              }
              forEach(sc_categories, (scs, sc_category) => {
                forEach(scs, (terrs, sc_name) => {
                  // Filter scs
                  if (
                    this.salesChannels.length &&
                    !this.salesChannels.includes(sc_name) &&
                    !this.salesChannels.includes(sc_category)
                  )
                    return;

                  forEach(terrs, ({ name, sales }, code) => {
                    if (!records[name])
                      records[name] = {
                        territory: code,
                        territory_name: name
                      };
                    if (!records[name][period]) records[name][period] = 0;
                    records[name][period] += parseInt(sales) || 0;

                    if (totals[period] === undefined) {
                      totals[period] = 0;
                    }

                    totals[period] += parseInt(sales) || 0;
                  });
                });
              });
            });
          });
        });
      });

      return { records, totals };
    },
    async exportCSV() {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch("reports/exportCSV", {
          date_range: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          authors: this.authors.join(","),
          titles: this.titles.join(","),
          sales_channels: this.salesChannels.join(","),
          user_ids: this.clients.join(",")
        });

        FileDownload(res, "sales-by-territory.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/salesByTitle", {
          period: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          type: this.type,
          showFree: this.showFree,
          showPaid: this.showPaid,
          user_ids: this.clients.join(",")
        });

        this.groupedData = res.data;
        this.rawData = res.data_raw;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
  computed: {
    titleListIds() {
      return this.titleList.map(t => t.id).join(",");
    },
    specialRegionSales() {
      return this.dataSource
        .filter(([territory]) => this.specialRegions.includes(territory))
        .map(([territory, sales]) => ({
          territory,
          sales
        }));
    },
    dataSource() {
      const data = map(this.formatData().records, o => [
        o.territory_name,
        sumBy(Object.values(o), v => (typeof v == "number" ? v : 0))
      ]);

      return [["Country", "Sales"], ...data];
    },
    headers() {
      return [
        "territory",
        ...this.dates.map(d => ({
          key: format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"),
          label: format(parse(d, "MMM-yyyy", new Date()), "MMM yyyy")
        }))
      ];
    },
    records() {
      const { records, totals } = this.formatData();
      return [
        ...Object.values(records),
        {
          territory: this.$t("total-count"),
          ...totals,
          is_total: true
        }
      ];
    },
    dates() {
      const startDate = parse(this.date_range[0], "MMM-yyyy", new Date());
      const endDate = parse(this.date_range[1], "MMM-yyyy", new Date());
      const diffInMonths = Math.abs(differenceInMonths(startDate, endDate)) + 1;

      return new Array(diffInMonths)
        .fill(0)
        .map((_, i) => format(addMonths(startDate, i), "MMM-yyyy"));
    },
    salesChannelsCategories() {
      return ["all", ...salesChannelsCategories];
    },
    titleList() {
      const titles = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, (d, id) => {
            if (!titles[id])
              titles[id] = {
                id: id,
                label: d.title
              };
          });
        });
      });
      return Object.values(titles);
    },
    authorList() {
      const authors = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (_, k) => {
              if (k === "title") return;
              if (!authors[k])
                authors[k] = {
                  id: k,
                  label: k
                };
            });
          });
        });
      });
      return Object.values(authors);
    },
    salesChannelList() {
      const channels = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (v, k) => {
              if (k === "title") return;
              forEach(v, (v, n) => {
                if (!n[0]) return;
                if (!channels[n]) {
                  channels[n] = {
                    label: n[0].toUpperCase() + n.slice(1),
                    id: n,
                    children: {}
                  };
                }

                forEach(v, (_, sales_channel_name) => {
                  if (!channels[n].children[sales_channel_name]) {
                    channels[n].children[sales_channel_name] = {
                      label: sales_channel_name,
                      id: sales_channel_name
                    };
                  }
                });
              });
            });
          });
        });
      });
      return Object.values(channels).map(c => ({
        ...c,
        children: Object.values(c.children)
      }));
    }
  },
  watch: {
    type() {
      this.get();
    },
    date_range() {
      this.get();
    },
    clients() {
      this.get();
    }
  }
};
</script>

<style lang="scss">
.special-sales {
  position: absolute;
  z-index: 999;
  right: 15px;
  bottom: 0;
}
</style>
